import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Logo } from "../components/Image";
import { faFiles, faXmark } from "@fortawesome/pro-regular-svg-icons";
import Modal from "../components/Modal";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { gql, useQuery } from "@apollo/client";

const WEBSITE_POSTS_QUERY = gql`
  query websitePostList($page: Int!, $limit: Int!) {
    websitePostList(input: { page: $page, limit: $limit }) {
      ok
      error
      results {
        id
        createdAt
        updatedAt

        description
        images {
          id
          createdAt
          updatedAt

          table
          column
          originalname
          encoding
          mimeType
          size
          fileUrl
          target
          timestamp
        }
        postLikes {
          id
          post {
            id
          }
          user {
            id
          }
        }
        user {
          id
          createdAt
          updatedAt

          role
          userId
          nickname
        }
        likeCount
      }
      totalPages
      totalResults
    }
  }
`;

function Section04() {
  const [modal, setModal] = useState(false);
  const modalShow = () => setModal(true);
  const modalHide = () => setModal(false);

  const [post, setPost] = useState(null);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(4);
  const { data, loading, refetch } = useQuery(WEBSITE_POSTS_QUERY, {
    fetchPolicy: "network-only",
    variables: { page: 0, limit },
  });
  const [refreshing, setRefreshing] = useState(false);
  const refresh = async () => {
    setPage(data.websitePostList.results.length);
    setLimit(data.websitePostList.results.length + 4);
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  //   if (loading) {
  //     return (
  //       <div className="panel-loader">
  //         <span className="spinner-small"></span>
  //       </div>
  //     );
  //   }

  if (data?.websitePostList.results.length > 0) {
    return (
      <div className="relative pt-5 overflow-hidden pb-72 ">
        <div className="flex flex-col justify-start px-4 m-auto md:px-5 max-w-7xl">
          <div className="items-center justify-center m-auto text-center mb-10">
            <Logo />
            <p className="mt-10 text-xl lg:leading-[4rem] leading-snug md:leading-normal md:text-3xl lg:text-5xl text-Deep">
              저상버스 인증샷 스토리
            </p>
          </div>
          <div>
            <div className="flex my-5 justify-center flex-wrap">
              {data?.websitePostList.results.map((post, index) => (
                <div
                  key={index}
                  className="relative w-full md:w-[24%] sm:w-[48%] bg-slate-400 h-64 rounded-2xl overflow-hidden my-1 mx-1"
                >
                  <div
                    className="ImageHover"
                    style={{
                      backgroundImage: `url(${post.images[0]?.fileUrl})`,
                    }}
                    onClick={() => {
                      modalShow();
                      setPost(post);
                    }}
                  >
                    <div className="HoverContent flex flex-col justify-between">
                      <div className=" justify-end flex">
                        <FontAwesomeIcon
                          icon={faFiles}
                          size="xl"
                          className="mr-2"
                        />
                        <p>{post.images.length}</p>
                      </div>
                      <p>{post.description}</p>
                      <p>@{post.user.userId}</p>
                    </div>
                  </div>
                </div>
              ))}
              {/* 모달 */}
              {modal && (
                <Modal isOpen={modal} onClose={modalHide} post={post} />
              )}
            </div>
            {data?.websitePostList.results.length <
              data?.websitePostList.totalResults && (
              <div className="w-full justify-center m-auto flex mt-10">
                <button
                  className="flex items-center justify-center py-2 px-5 rounded-full"
                  style={{ borderWidth: 1, borderColor: "#c3c3c3" }}
                  onClick={refresh}
                >
                  <FontAwesomeIcon icon={faPlus} className="text-gray-500" />
                  <p className="text-gray-500 inline-flex ml-2 text-lg font-normal">
                    더보기
                  </p>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Section04;
