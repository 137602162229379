import React from 'react';
import { ArrowDown, Logo } from '../components/Image';

const List = [
    {
        icon: require('../image/icon-01.png'),
        title: '저상버스 탑승요청',
        text: '어디서 타고, 내리는지\n기사님께 미리 알려주세요.',
    },
    {
        icon: require('../image/icon-02.png'),
        title: '저상버스 경로검색',
        text: '최신 업데이트 정보로,\n최적의 경로를 알려드려요.',
    },
    {
        icon: require('../image/icon-03.png'),
        title: '저상버스 알림기능',
        text: '저상버스가 언제 오는지\n실시간으로 알려드려요.',
    },
];
const divStyles = {
    boxShadow: '0 5px 80px rgba(169,169,169,0.25)',
};
const iconStyles = {
    boxShadow: '0 5px 50px #EFF7FF',
};
function Section01() {
    return (
        <div className="relative px-4 pb-10 md:px-5 md:pb-44">
            <img
                src={require('../image/back-img.png')}
                className="absolute -translate-x-1/2 translate-y-1/2 left-1/2 bottom-1/3"
            />
            <div className="flex flex-col px-3 m-auto md:px-5 max-w-7xl">
                <div className="w-10 m-auto mt-10 mb-20 md:w-20 md:mt-28 md: mb-44">
                    <ArrowDown />
                </div>
                <div className="items-center justify-center m-auto text-center">
                    <Logo />
                    <p className="mt-10 text-xl leading-snug lg:leading-[4rem] md:leading-normal md:text-3xl lg:text-5xl text-Deep">
                        누구나 동등하게 버스를
                        <br className="visible md:flex" />
                        이용할 수 있도록 지원합니다.
                    </p>
                    <p className="text-base md:text-3xl leading-6 font-medium mb-10 md:mb-28 text-Gray500 mt-3 md:mt-7 md:leading-[2.8rem]">
                        버스를 못 탈까 걱정되시나요?
                        <br />
                        버스를 탈 때 미안한 감정을 느끼시나요?
                    </p>
                </div>
                <div className="flex flex-col justify-center space-x-0 md:space-x-5 xl:space-x-20 md:flex-row">
                    {List.map((i, index) => {
                        return (
                            <div
                                key={index}
                                className="flex z-10 flex-col items-center w-full md:w-1/3 px-4 md:px-6 xl:px-10 py-8 md:py-11 xl:py-16 bg-white xl:rounded-tr-[100px] xl:rounded-br-[20px] xl:rounded-tl-[20px] xl:rounded-bl-[100px] rounded-tr-[20px] rounded-br-[5px] rounded-tl-[5px] rounded-bl-[20px] mb-4 md:mb-0"
                                style={divStyles}
                            >
                                <div
                                    className="flex items-center justify-center bg-white shadow-lg w-[100px] h-[94px] md:w-[140px] md:h-[126px] xl:w-[160px] xl:h-[154px] rounded-3xl xl:rounded-[50px] md:rounded-[35px]"
                                    style={iconStyles}
                                >
                                    <img src={i.icon} className="w-10 md:w-10 xl:w-1/2" />
                                </div>
                                <p className="mt-6 mb-4 text-lg font-bold tracking-tight xl:mb-6 md:text-xl xl:text-3xl text-Deep">
                                    {i.title}
                                </p>
                                <p className="text-base font-medium text-center whitespace-pre-wrap md:text-lg xl:text-2xl text-Gray500">
                                    {i.text}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Section01;
