import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const TOKEN = "TOKEN";
const ME = "ME";

export const isLoggedInVar = makeVar(Boolean(localStorage.getItem(TOKEN)));

export const logUserIn = (token, me) => {
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(ME, JSON.stringify(me));
  isLoggedInVar(true);
};

export const logUserOut = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(ME);
  window.location.reload();
};

// export const uri = "http://192.168.1.224:3000";
// export const uri = "http://192.168.50.223:3000";
// export const uri = "http://localhost:3000";
export const uri = "https://api.webus.kr";

const httpLink = createHttpLink({
  uri: uri === "api.webus.kr" ? `${uri}/graphql` : `${uri}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-jwt": localStorage.getItem(TOKEN),
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    // typePolicies: {
    //   User: {
    //     keyFields: (obj) => `User:${obj.username}`,
    //   },
    // },
  }),
});
