import Footer from "../section/Footer";
import Maintop from "../section/Maintop";
import Section01 from "../section/Section01";
import Section02 from "../section/Section02";
import Section03 from "../section/Section03";
import Section04 from "../section/Section04";

const Main = () => {
  return (
    <div className="flex flex-col w-screen bg-white">
      <Maintop />
      <Section01 />
      <Section02 />
      <Section03 />
      <Section04 />
      <Footer />
    </div>
  );
};

export default Main;
