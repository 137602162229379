import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/swiper-bundle.min.css';
import "swiper/swiper-bundle.css";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import nl2br from "nl2br";

const Modal = ({ isOpen, onClose, post }) => {
  const [slideInfo, setSlideInfo] = useState(""); // 슬라이드 정보 상태 변수 추가

  // 슬라이드 변경 이벤트 핸들러
  const handleSlideChange = (swiper) => {
    const currentPage = swiper.activeIndex + 1;
    const totalSlides = swiper.slides.length;
    setSlideInfo(`${currentPage} / ${totalSlides} `);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  // 더보기 버튼 클릭 시 상태 변경
  const handleToggleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const createdAt = new Date(post.createdAt);

  return (
    <>
      {isOpen && (
        <div
          className="modal z-20 fixed  items-end md:items-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          // onClick={onClose} // 모달 외부 클릭 시 모달 닫기
        >
          <div
            className="modal-content w-full p-0  md:w-[600px] md:p-5   rounded-2xl rounded-b-none md:rounded-b-2xl"
            style={{
              backgroundColor: "#fff",
            }}
            onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 시 모달 닫히지 않도록 이벤트 전파 방지
          >
            {/* 모달 내용 */}
            <div className="flex justify-between items-center py-4 px-3 md:pt-0 md:px-0">
              <div>
                <p
                  className={
                    "text-base leading-6 font-medium tracking-tight md:text-md  text-gray-800"
                  }
                >
                  @ {post.user.userId}
                </p>
                <p
                  className={
                    "text-sm leading-5 text-gray-400 font-light  tracking-tight md:text-md "
                  }
                >
                  {createdAt.getFullYear()}. {createdAt.getMonth() + 1}.{" "}
                  {createdAt.getDate()}
                </p>
              </div>
              <button
                onClick={onClose}
                style={{ width: 50, height: 50 }}
                className="flex items-center justify-center"
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="xl"
                  className="  text-gray-600"
                />
              </button>
            </div>
            <div className="relative h-52 md:h-80">
              <Swiper
                slidesPerView={1}
                className=" rounded-md  bg-slate-50"
                style={{ height: "100%" }}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{ delay: 3000 }}
                onSlideChange={handleSlideChange}
              >
                {post.images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className=" justify-center items-center flex h-full">
                      <img
                        className="h-full"
                        src={image?.fileUrl}
                        alt={`Image ${index + 1}`}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className="absolute bottom-3 right-3 py-1 px-3 rounded-full z-10"
                style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
              >
                <p className={"text-sm leading-5 font-light text-white"}>
                  {slideInfo}
                </p>
              </div>
            </div>
            <div className="py-5 px-3">
              <div className="flex  justify-start items-center mb-3  text-gray-800">
                <FontAwesomeIcon icon={faHeart} size="lg" className="mr-2" />
                <p
                  className={
                    "text-base font-normal leading-4 md:text-md tracking-tight"
                  }
                >
                  {post.likeCount}
                </p>
              </div>
              <div>
                <p
                  className={
                    "text-sm font-normal text-gray-800 md:text-md leading-relaxed tracking-tight"
                  }
                >
                  {isExpanded ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: nl2br(post.description),
                      }}
                    />
                  ) : (
                    post.description.slice(0, 150) + "..."
                  )}
                  {!isExpanded && (
                    <button
                      onClick={handleToggleClick}
                      className="text-xs md:text-sm font-light text-gray-800 md:text-md leading-relaxed tracking-tight"
                    >
                      더보기
                    </button>
                  )}
                </p>
                {/* 전체 텍스트가 보이지 않을 때만 더보기 버튼 표시 */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
