export const SymbolWhite = () => {
  return <img src={require("../image/symbol-w.png")} />;
};
export const Symbol = () => {
  return (
    <div className="w-10 md:w-14">
      <SymbolWhite />
    </div>
  );
};
export const ArrowDown = () => {
  return <img src={require("../image/down-arrow.png")} />;
};
export const Logo = () => {
  return (
    <img
      src={require("../image/logo.png")}
      className="w-[100px] md:w-[160px] m-auto"
    />
  );
};
export const LogoLeft = () => {
  return (
    <>
      <img
        src={require("../image/logo.png")}
        className="w-[100px] md:w-[160px] m-auto md:m-0"
      />
    </>
  );
};
export const LogoWhite = () => {
  return <img src={require("../image/logo-w.png")} />;
};
export const Wezon = () => {
  return <img src={require("../image/footer-icon.png")} className="w-full" />;
};
