import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../components/Image";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import background from "../image/bottom-img.png";
import background1 from "../image/banner_01.png";
import background2 from "../image/banner_02.png";
import background3 from "../image/banner_03.png";

const Banner = [
  {
    background: background1,
  },
  {
    background: background2,
  },
  {
    background: background3,
  },
];
const DownLink = [
  {
    icon: faApple,
    smtext: "Available on the",
    lgtext: "App Store",
    href: "https://apps.apple.com/kr/app/id1631103778",
  },
  {
    icon: faGooglePlay,
    smtext: "Get it on",
    lgtext: "Google Play",
    href: "https://play.google.com/store/apps/details?id=com.wezoncoop.webus",
  },
];
function Section03() {
  return (
    <div className="relative pt-5 overflow-hidden ">
      <div className="flex flex-col justify-start px-4 m-auto md:px-5 max-w-7xl">
        <div className="items-center justify-center m-auto text-center">
          <Logo />
          <p className="mt-10 text-xl lg:leading-[4rem] leading-snug md:leading-normal md:text-3xl lg:text-5xl text-Deep">
            위버스는 이동권 개선을 위해
            <br className="hidden md:flex" />
            파트너쉽을 맺고 있습니다.
          </p>
          <p className="text-base md:text-3xl leading-6  text-center  font-medium mb-2 md:mb-28 text-Gray500 mt-3 md:mt-7 md:leading-[2.8rem]">
            모든 활동은 "이동"에서 시작하기에,
            <br />
            이동에 모든 제약을 없애기 위해 함께합니다.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center my-5 space-x-0 md:mt-20 md:mb-20 md:space-x-10 md:flex-row">
          {Banner.map((i, index) => {
            return (
              <div
                key={index}
                className="relative w-[60%] md:w-[280px] h-14 md:h-20 "
                style={{
                  backgroundImage: `url("${i.background}")`,
                  backgroundPosition: "center center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            );
          })}
        </div>
        <div className="flex flex-col justify-center mt-5 space-y-5 md:mt-16 md:space-y-0 md:flex-row">
          {DownLink.map((i, index) => {
            return (
              <a
                key={index}
                className="flex px-5 md:px-10 py-4 mr-6 bg-LightDefault  rounded-full border-[1.5px]  items-center w-full md:w-auto justify-center md:justify-start"
                href={i.href}
              >
                <FontAwesomeIcon
                  icon={i.icon}
                  size={"2x"}
                  className="text-white"
                />
                <div className="ml-2 md:ml-4">
                  <p className="mb-0.5 md:text-sm text-xs font-normal text-white">
                    {i.smtext}
                  </p>
                  <p className="text-base font-medium text-white md:text-xl">
                    {i.lgtext}
                  </p>
                </div>
              </a>
            );
          })}
        </div>
        <div className="mt-12 mb-36 ">
          <div className="hidden w-auto md:block w-full  md:h-[34rem]">
            <div
              className="w-full md:w-[100%] h-full"
              style={{
                backgroundImage: `url("${background}")`,
                backgroundPosition: "center top",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section03;
