import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const AppLink = ({ icon, size, smtext, lgtext }) => {
  return (
    <Link className="flex px-10 py-4 mr-6 bg-white border-white rounded-full border-[1.5px] bg-opacity-20 items-center ">
      <FontAwesomeIcon icon={icon} size={"2x"} className="text-white" />
      <div className="ml-4">
        <p className="mb-0.5 text-sm font-normal text-white">{smtext}</p>
        <p className="text-xl font-medium text-white">{lgtext}</p>
      </div>
    </Link>
  );
};
