import React from "react";

const LocationBased = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-Default">
      <div className="max-w-3xl px-5 py-10 md;py-20">
        <p className="mt-20 mb-10 text-3xl font-bold tracking-tight text-white md:mb-36 md:text-7xl">
          위치기반서비스
        </p>
        <p className="text-base font-normal leading-8 tracking-tight text-white md:leading-10 md:text-xl">
          <br />
          <br />제 1 조 (목적)
          <br />
          <br />이 약관은 위즈온협동조합 (이하 “회사”)이 제공하는
          위치기반서비스와 관련하여 회사와 위치정보주체(개인)와의 권리, 의무 및
          책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
          <br />
          <br />
          <br />
          <br />제 2 조 (약관 외 준칙)
          <br />
          <br />이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한
          법률, 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
          전기통신기본법, 전기통신사업법 등 관계법령과 회사의 이용약관 및
          개인정보처리방침, 회사가 별도로 정한 지침 등에 의합니다.
          <br />
          <br />
          <br />
          <br />제 3 조 (서비스 내용)
          <br />
          <br />
          ①회사는 위치정보사업자로부터 위치정보를 전달받아 다음과 같은
          위치기반서비스를 제공하며, 개인위치정보의 이용 목적 및 기간은 아래와
          같습니다
          <br />
          <br />
          서비스 명 : 이용자 위치 기반 경로 검색
          <br /> <br />
          서비스 내용 및 이용 목적
          <br />- 목적지까지 이동 경로 안내 (이용자 위치 기반, 도보 및 버스)
          <br />- 경로 내 버스 정거장 도착 정보 안내
          <br />- 서비스 통계 자료 생성
          <br />
          <br />
          개인위치정보 보유기간 : 1년
          <br />
          <br />
          ②제1항 위치기반서비스의 이용요금은 무료입니다.
          <br />
          <br />
          <br />
          <br />제 4 조 (개인위치정보주체의 권리)
          <br />
          <br />
          ①개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중 일부
          또는 개인위치정보의 이용ㆍ제공 목적, 제공받는 자의 범위 및
          위치기반서비스의 일부에 대하여 동의를 유보할 수 있습니다.
          <br />
          <br />
          ②개인위치정보주체는 개인위치정보의 수집ㆍ이용ㆍ제공에 대한 동의의 전부
          또는 일부를 철회할 수 있습니다.
          <br />
          <br />
          ③개인위치정보주체는 언제든지 개인위치정보의 수집ㆍ이용ㆍ제공의
          일시적인 중지를 요구할 수 있습니다.
          <br />
          <br />
          ④개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를 요구할
          수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수
          있습니다.
          <br />이 경우 회사는 정당한 이유 없이 요구를 거절하지 아니합니다.
          <br />
          1.개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료
          <br />
          2.개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에 관한
          법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및 내용
          <br />
          <br />
          ⑤회사는 개인위치정보주체가 동의의 전부 또는 일부를 철회한 경우에는
          지체 없이 수집된 개인위치정보 및 위치정보 수집ㆍ이용ㆍ제공사실
          확인자료를 파기합니다.
          <br />
          단, 동의의 일부를 철회하는 경우에는 철회하는 부분의 개인위치정보 및
          위치정보 수집ㆍ이용ㆍ제공사실 확인자료에 한합니다.
          <br />
          <br />
          ⑥개인위치정보주체는 제1항 내지 제4항의 권리행사를 위하여 이 약관
          제13조의 연락처를 이용하여 회사에 요구할 수 있습니다.
          <br />
          <br />
          <br />
          <br />제 5 조 (법정대리인의 권리)
          <br />
          <br />
          ②법정대리인은 만14세 미만 아동의 개인위치정보를 수집ㆍ이용ㆍ제공에
          동의하는 경우 동의유보권, 동의철회권 및 일시중지권, 열람ㆍ고지요구권을
          행사할 수 있습니다.
          <br />
          <br />
          <br />
          <br />제 6 조 (위치정보 이용ㆍ제공사실 확인자료 보유근거 및 보유기간)
          <br />
          <br />
          회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여
          개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를
          위치정보시스템에 자동으로 기록하며, 6개월 이상 보관합니다.
          <br />
          <br />
          <br />
          <br />제 7 조 (서비스의 변경 및 중지)
          <br />
          <br />
          ①회사는 위치기반서비스사업자의 정책변경 등과 같이 회사의 제반 사정
          또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우, 서비스의 전부
          또는 일부를 제한, 변경하거나 중지할 수 있습니다.
          <br />
          <br />
          ②제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에
          공지하거나 개인위치정보주체에게 통지합니다.
          <br />
          <br />
          <br />
          <br />제 8 조 (개인위치정보 제3자 제공 시 즉시 통보)
          <br />
          <br />
          ①회사는 개인위치정보주체의 동의 없이 당해 개인위치정보주체의
          개인위치정보를 제3자에게 제공하지 아니하며, 제3자 제공 서비스를
          제공하는 경우에는 제공받는 자 및 제공목적을 사전에
          개인위치정보주체에게 고지하고 동의를 받습니다.
          <br />
          <br />
          ②회사는 개인위치정보를 개인위치정보주체가 지정하는 제3자에게 제공하는
          경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회
          개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을 즉시
          통보합니다.
          <br />
          <br />
          ③다만, 아래에 해당하는 경우에는 개인위치정보주체가 미리 특정하여
          지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.
          <br />
          1.개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의
          수신기능을 갖추지 아니한 경우
          <br />
          2.개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치 외의
          통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한 경우
          <br />
          <br />
          <br />
          <br />제 9 조 (8세 이하의 아동 등의 보호의무자의 권리)
          <br />
          <br />
          ①회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라 함)의
          보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여
          개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는
          것으로 봅니다.
          <br />
          1.8세 이하의 아동
          <br />
          2.피성년후견인
          <br />
          3.장애인복지법 제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서
          장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한 중증장애인에
          해당하는 자(장애인복지법 제32조의 규정에 의하여 장애인등록을 한 자에
          한정)
          <br />
          <br />
          ②8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의
          이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에
          보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
          <br />
          <br />
          ③보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
          동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.
          <br />
          <br />
          <br />
          <br />제 10 조 (손해배상)
          <br />
          <br />
          개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률
          제15조 내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에
          대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이
          없음을 입증하지 아니하면 책임을 면할 수 없습니다.
          <br />
          <br />
          <br />
          <br />제 11 조 (분쟁의 조정)
          <br />
          <br />
          ①회사는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와 협의가
          이루어지지 아니하거나 협의를 할 수 없는 경우에는 방송통신위원회에
          재정을 신청할 수 있습니다.
          <br />
          <br />
          ②회사 또는 개인위치정보주체는 위치정보와 관련된 분쟁에 대해 당사자간
          협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
          개인정보보호법에 따라 개인정보분쟁조정위원회에 조정을 신청할 수
          있습니다.
          <br />
          <br />
          <br />
          <br />제 12 조 (사업자 정보 및 위치정보 관리책임자)
          <br />
          <br />
          ①회사의 상호, 주소 및 연락처는 다음과 같습니다.
          <br />- 상호: 위즈온협동조합
          <br />- 주소: 대전시 중구 중앙로 124 (대화동, 이화빌딩 2층) 우)34913
          <br />- 전화번호: 042-825-5078
          <br />
          <br />
          ②회사는 다음과 같이 위치정보 관리책임자를 지정하여 이용자들이 서비스
          이용과정에서 발생한 민원사항 처리를 비롯하여 개인위치정보주체의 권리
          보호를 위해 힘쓰고 있습니다.
          <br />- 위치정보 관리책임자 : 안정식 / 메일 : wezon@wezon.co.kr
          <br />
          <br />
          <br />
          부칙
          <br />
          <br />
          제 1 조 시행일
          <br />이 약관은 2022년 10월 27일부터 시행한다.
          <br />
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default LocationBased;
