import React from "react";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import Router from "./config/Router";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";

function App() {
  return (
    <ApolloProvider client={client}>
      <RouterProvider router={Router} />
    </ApolloProvider>
  );
}

export default App;
