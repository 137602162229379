import React from "react";
import { AppLink } from "../components/Link";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { Symbol } from "../components/Image";
import background from "../image/main-img.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const DownLink = [
  {
    icon: faApple,
    smtext: "Available on the",
    lgtext: "App Store",
    href: "https://apps.apple.com/kr/app/id1631103778",
  },
  {
    icon: faGooglePlay,
    smtext: "Get it on",
    lgtext: "Google Play",
    href: "https://play.google.com/store/apps/details?id=com.wezoncoop.webus",
  },
];

function Maintop() {
  return (
    <div
      className="relative w-full bg-cover h-screen md:h-[750px]"
      style={{
        backgroundImage: `url("${background}")`,
        backgroundPosition: "bottom right",
      }}
    >
      <div className="absolute top-0 z-10 w-full h-full bg-black opacity-50" />
      <div className="absolute top-0 left-0 right-0 z-10 items-center justify-center w-full h-full">
        <div className="z-10 flex flex-col justify-center h-full px-4 m-auto md:px-5 max-w-7xl md:justify-start ">
          <div className="pt-0 pb-10 md:pt-16 ">
            <Symbol />
          </div>
          <h2 className="font-bold leading-tight md:leading-[1.1] text-white text-4xl  md:text-7xl ">
            저상버스 <span className="opacity-50">타고</span>
            <br />
            어디<span className="opacity-50">로 갈까요?</span>
          </h2>
          <p className="text-lg font-normal md:leading-[2.8rem] text-white md:text-3xl mt-10">
            모두의 삶의 영역이 확장되는,
            <br />더 나은 대중교통을 만들어나갑니다.
          </p>
          <div className="flex flex-col mt-5 space-y-5 md:mt-16 md:space-y-0 md:flex-row">
            {DownLink.map((i, index) => {
              return (
                <a
                  key={index}
                  className="flex px-5 md:px-10 py-4 mr-6 bg-white border-white rounded-full border-[1.5px] bg-opacity-20 items-center w-full md:w-auto justify-center md:justify-start"
                  href={i.href}
                >
                  <FontAwesomeIcon
                    icon={i.icon}
                    size={"2x"}
                    className="text-white"
                  />
                  <div className="ml-2 md:ml-4">
                    <p className="mb-0.5 md:text-sm text-xs font-normal text-white">
                      {i.smtext}
                    </p>
                    <p className="text-base font-medium text-white md:text-xl">
                      {i.lgtext}
                    </p>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maintop;
