import React, { useState } from "react";
import styled from "styled-components";
import { Logo, LogoLeft } from "../components/Image";
const divStyles = {
  boxShadow: "0 4px 20px #5081FF",
};

const types = [
  {
    id: 1,
    title: "저상버스 검색",
    img: require("../image/screen_01.png"),
  },
  {
    id: 2,
    title: "저상버스 탑승 요청",
    img: require("../image/screen_02.png"),
  },
];

function Section02() {
  const [active, setActive] = useState(types[0]);

  return (
    <div className="relative py-10 md:py-72">
      <div className="flex flex-col justify-between max-w-full px-4 m-auto md:px-5 md:max-w-7xl">
        <div className="flex flex-col justify-center max-w-full lg:max-w-xl">
          <LogoLeft />
          <p className="text-xl leading-snug lg:leading-[4rem] text-center md:text-left mt-14 md:leading-normal md:text-3xl lg:text-5xl text-Deep">
            장애인 콜택시를 기다리며,
            <br className="visible md:flex" />
            한번, 시작해 보세요!
          </p>
          <p className="text-base md:text-3xl leading-6 text-center md:text-left font-medium mb-2 md:mb-28 text-Gray500 mt-3 md:mt-7 md:leading-[2.8rem]">
            지하철을 이용하듯
            <br />
            저상버스를 이용할 수 있다면 어떨까요?
          </p>
          <div className="flex flex-row items-stretch justify-end w-full py-5 pr-2 md:pr-10">
            <div className="flex flex-col w-auto">
              {types.map((type) => (
                <button
                  key={type.id}
                  className={"w-auto text-right relative py-2"}
                  onClick={() => setActive(type)}
                  style={{
                    backgroundColor:
                      active === type ? "#defaultColor" : "#grayColor",
                  }}
                >
                  <p
                    className={
                      "font-bold text-lg md:text-3xl leading-relaxed md:leading-loose tracking-tight" +
                      (active === type ? " text-Default" : " text-Gray300")
                    }
                  >
                    {type.title}
                  </p>
                  <div
                    className={
                      "md:w-9 md:h-9 w-5 h-5 bg-Default absolute -right-[43.1px]  md:-right-[79px] top-1/2 z-20 rounded-full -translate-y-2/4" +
                      (active === type ? " flex" : " hidden")
                    }
                    style={divStyles}
                  />
                </button>
              ))}
            </div>
            <div className="w-4 ml-5 rounded-full md:w-6 md:ml-10 bg-Gray100 " />
          </div>
        </div>
      </div>
      {types[0].id === active.id && (
        <div className="relative top-auto right-auto max-w-full m-auto lg:max-w-2xl 2xl:max-w-4xl xl:absolute xl:top-32 xl:-right-20">
          <img src={require("../image/screen_01.png")} />
        </div>
      )}
      {types[1].id === active.id && (
        <div className="relative top-auto right-auto max-w-full m-auto lg:max-w-2xl 2xl:max-w-4xl xl:absolute xl:top-32 xl:-right-20">
          <img src={require("../image/screen_02.png")} />
        </div>
      )}
    </div>
  );
}

export default Section02;
