import React from "react";

const PersonalLocation = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-Default">
      <div className="max-w-3xl px-5 py-10 md;py-20">
        <p className="mt-20 mb-10 text-3xl font-bold tracking-tight text-white md:mb-36 md:text-7xl">
          개인위치정보처리방침
        </p>
        <p className="text-base font-normal leading-8 tracking-tight text-white md:leading-10 md:text-xl">
          <br />
          위즈온협동조합 개인위치정보 처리방침 본 개인정보 처리방침은 2022년
          10월 27일부터 시행됩니다.위즈온협동조합이 취급하는 모든 개인위치정보는
          개인정보보호법, 위치정보의 보호 및 이용 등에 관한 법률 등 관련법령상의
          개인위치정보보호 규정을 준수하여 이용자의 개인위치정보보호 및 권익을
          보호하고 개인위치정보와 관련된 이용자의 고충을 원활하게 처리할 수
          있도록 다음과 같은 개인위치정보 처리방침을 두고 있습니다.
          <br />
          <br />
          제1조 (개인위치정보 처리목적 및 보유기간)
          <br />
          위즈온협동조합은 위치 서비스 이용약관에서 정한 목적 범위 내에서
          개인위치정보를 이용·제공하며, 서비스 이행 및 고객불만 응대를 위해 최대
          3개월간 보유합니다.
          <br />
          <br />
          제2조 (확인자료의 보유근거 및 보유기간)
          <br />
          위즈온협동조합은 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에
          따라 개인위치정보 이용·제공사실 확인자료를 자동으로 기록·보존하며,
          해당 자료는 6개월간 보관합니다.
          <br />
          <br />
          제3조 (파기 절차 및 방법)
          <br />
          위즈온협동조합은 회원탈퇴 등 처리목적이 달성되거나 보유기간이 경과하면
          복구 및 재생이 불가능하도록 지체없이 파기합니다.
          <br />
          <br />
          제4조 (제3자 제공에 관한 사항)
          <br />
          위즈온협동조합은 개인위치정보주체의 사전 동의 없이 위치정보를 외부에
          제공하지 않습니다. 단, 개인위치정보주체가 직접 동의를 한 경우, 그리고
          관련 법령에 의거해 제출 의무가 발생한 경우, 이용자의 생명이나 안전에
          급박한 위험이 확인되어 이를 해소하기 위한 경우에 한하여 개인위치정보를
          제공합니다.
          <br />
          <br />
          제5조 (통보에 관한 사항)
          <br />
          ① 위즈온협동조합은 개인위치정보를 이용자가 지정하는 제3자에게 제공하는
          경우 개인위치정보를 수집한 통신단말장치로 매회 이용자에게 제공받는 자,
          제공일시 및 제공목적을 즉시 통지합니다. 단, 아래의 경우 이용자가 미리
          특정하여 지정한 통신단말장치 또는 전자우편주소, 온라인게시 등으로
          통지합니다.
          <br />
          1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의
          수신기능을 갖추지 아니한 경우
          <br />
          2. 이용자의 개인위치정보를 수집한 통신단말장치 외의 통신단말장치 또는
          전자우편주소, 온라인게시 등으로 통보할 것을 미리 요청한 경우
          <br />
          <br />
          제6조(보호의무자의 권리·의무 및 그 행사방법에 관한 사항)
          <br />
          ① 위즈온협동조합은 아래의 경우에 해당하는 자(이하 “8세 이하의 아동
          등”)의 위치정보의 보호 및 이용 등에 관한 법률 제26조2항에 해당하는
          자(이하 “보호의무자”)가 8세 이하의 아동 등의 생명 또는 신체보호를
          위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가
          있는 것으로 봅니다.
          <br />
          1. 8세 이하의 아동
          <br />
          2. 피성년후견인
          <br />
          3. 장애인복지법 제2조제2항제2호에 따른 정신적 장애를 가진 사람으로서
          장애인고용촉진 및 직업재활법 제2조제2호에 따른 중증장애인에 해당하는
          사람(장애인복지법 제32조에 따라 장애인 등록을 한 사람만 해당) <br />
          ② 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의
          이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에
          보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 하며,
          보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
          동의하는 경우 위치 서비스 약관상 이용자의 권리를 모두 가집니다.
          <br />
          <br />
          제7조(개인위치정보 관리·보호 책임자)
          <br />
          위즈온협동조합은 개인위치정보를 적절히 관리·보호하고, 이용자의 불만,
          요청 등을 원활하게 처리할 수 있도록 개인위치정보 관리·보호 책임자를
          지정하여 운영하고 있습니다. 개인위치정보 관리·보호 책임자의 성명과
          연락처는 아래와 같습니다.
          <br />
          개인위치정보 관리책임자 : 개발팀 안정식
          <br />
          개인위치정보 취급자 : 개발팀 이송이 <br />
          개인위치정보 관리자 : 경영기획팀 이기백
          <br />
          전화: 042-825-5078
          <br />
          FAX: 0504-156-2918
          <br />
          이메일: wezon@wezon.co.kr
          <br />
          <br />
          제8조(위치정보보호 조직의 구성·운영에 관한 사항)
          <br />
          위즈온협동조합은 위치정보보호 활동을 체계적으로 이행하기 위해
          위즈온협동조합 특성에 적합한 위치정보보호 조직을 구성하도록 하며,
          구성원 각자의 책임과 권한을 다음과 같이 규정하고 있습니다.
          <br />
          개인위치정보 관리책임자 : 위즈온협동조합 위치정보관련 업무 승인/총괄
          <br />
          개인위치정보 취급자 : 위즈온협동조합 위치정보관련 업무 담당
          <br />
          개인위치정보 관리자 : 위즈온협동조합 위치정보관련 점검
          <br />
          <br />
          제9조(위치정보관리책임자 및 위치정보취급자의 의무와 책임)
          <br />
          ① 위즈온협동조합은 위치정보를 취급할 수 있는 자를 다음 각 호의 1에
          해당하는 자로 정하여 최소한으로 제한하고 있습니다.
          <br />
          1. 위치정보관리책임자 등 위치정보 관리업무를 수행하는 자
          <br />
          2. 기타 업무상 위치정보의 취급이 불가피한 자
          <br />
          ② 위즈온협동조합은 다음 각 호를 포함하는 위치정보의 취급과 관리에
          필요한 절차를 포함하는 지침을 통하여 위치정보취급자로 하여금 이를
          숙지하고 준수하도록 하고 있습니다.
          <br />
          1. 위치정보의 이용·제공 사실 확인자료의 열람·정정 요구 등 위치정보와
          관련한 개인위치정보주체의 요구 및 이의·불만 대응에 관한 사항
          <br />
          2. 위치정보관리책임자 지정 등의 위치정보보호 조직의 구성·운영에 관한
          사항
          <br />
          3. 위치정보취급자의 정기적인 교육에 관한 사항
          <br />
          4. 기타 위치정보의 안전한 취급과 관리를 위해 필요한 사항
          <br />
          <br />
          제10조(위치정보의 취급·관리의 절차에 관한 사항)
          <br />
          ① 위즈온협동조합은 다음과 같이 위치정보의 취급·관리의 절차를 이행하고
          있습니다.
          <br />
          1. 위치정보관리책임자의 지정
          <br />
          2. 위치정보의 이용·제공·파기 등 각 단계별 접근 권한자 지정 및 권한의
          제한
          <br />
          3. 위치정보 취급자의 의무와 책임을 규정한 취급·관리 절차 및 지침 마련
          <br />
          4. 위치정보 제공사실 등을 기록한 취급 대장의 운영·관리
          <br />
          <br />
          제11조(위치정보 침해사고 등이 발생한 경우의 대응방법 및 절차)
          <br /> ① 위즈온협동조합은 위치정보 침해사고 발생 시 신속한 대응을 통한
          피해 발생을 최소화하기 위해 다음과 같은 절차를 마련하고 있습니다.
          <br />
          1. 위치정보 유출사실을 대표자에게 신속하게 보고하고 새로운 상황이
          발생될 때마다 수시로 대응하기 위한「위치정보유출 신속대응팀」을
          운영합니다.
          <br />
          2. 위치정보 유출 원인을 파악한 후, 피해 최소화 등을 위해 취약점 제거
          등 유출 원인을 제거하는 긴급 대응 조치를 실시하도록 합니다.
          <br />
          3. 위치정보 주체에게 유출 사실을 통지하고 개인정보보호위원회 또는
          한국인터넷진흥원에 유출 사실을 신고합니다.
          <br />
          4. 위치정보의 유출로 인한 정보주체 피해구제 등 지원 방안을 마련하고
          유사 사고의 재발방지를 위해 대책을 수립·시행합니다.
          <br />
          <br />
          제12조(위치정보 주체의 요구 및 이의·불만 대응에 관한 사항)
          <br />
          위즈온협동조합은 위치정보 주체의 요구 및 이의·불만 발생 시 다음과 같은
          절차에 따라 대응하고 있습니다.
          <br />
          위치정보사업자등에 대한 열람 또는 고지 요구
          <br />① 개인위치정보주체의 인적사항(다만, 대리인에 의하여 대리
          청구하는 경우에는 대리인과의 관계 및 대리권의 표시를 포함)②
          열람·고지를 요구한다는 의사③ 열람·고지를 요구하는 내역
          <br />
          본인 및 대리인의 확인
          <br />
          위치정보사업자등은 열람·고지의 요구를 받은 때에는 요구자가 당해
          개인위치정보주체(공인인증서, 핸드폰 인증 등을 통한 신분증명) 또는
          정당한 대리인(위임장 등)임을 확인하여야 함
          <br />
          개인위치정보주체에 대한 열람·고지의 결정 및 통지
          <br />
          열람의 허용여부를 즉시 알려야 하며 열람·고지를 허용하지 않는 경우에는
          그 사유와 근거를 설명하여 함
          <br />
          열람·고지의 실시
          <br />
          위치정보사업자등은 열람·고지를 실시함에 있어서 제3자의 개인위치정보 및
          여타 개인정보가 누설되지 아니하도록 필요한 조치를 취하여야 함
          <br />
          <br />
          제13조(위치정보취급자의 정기적인 교육에 관한 사항)
          <br />
          위즈온협동조합은 위치정보취급자에게 위치정보보호에 대한 인식을
          제고시키고 위치정보보호의 필요성을 이해시키며 사내에 구현된
          관리적·기술적 보호조치 등에 대한 정확한 교육 및 훈련 프로그램을 인터넷
          교육, 그룹웨어 교육 등 다양한 방법을 활용하여 자체적으로 실시하고
          있습니다.
          <br />
          <br />
          제14조(개인위치정보 처리방침 변경)
          <br />이 개인위치정보 처리방침은 2022. 10. 27.부터 적용됩니다.
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};
export default PersonalLocation;
