import React from "react";
import { createBrowserRouter } from "react-router-dom";
import LocationBased from "../routes/LocationBased";

import Main from "../routes/Main";
import PrivacyPolicy from "../routes/PrivacyPolicy";
import Terms from "../routes/Terms";
import PersonalLocation from "../routes/PersonalLocation";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/privacypolicy",
    exact: true,
    title: "개인정보취급방침",
    element: <PrivacyPolicy />,
  },
  {
    path: "/PersonalLocation",
    exact: true,
    title: "개인위치정보처리방침",
    element: <PersonalLocation />,
  },
  {
    path: "/locationbased",
    exact: true,
    title: "위치기반서비스",
    element: <LocationBased />,
  },
  {
    path: "/terms",
    exact: true,
    title: "이용약관",
    element: <Terms />,
  },
]);

export default Router;
